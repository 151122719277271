import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import React from "react";
import styled from "@emotion/styled";
import UserCircle from "../assets/img/UserCircle.svg";
import {
  handleNotification,
  handleDeleteNotification,
} from "../helper/APIFunctions";
import { toast } from "react-toastify";

const Textarea = styled.textarea`
  width: 95%;
  height: 200px;
  margin-right: 4rem;
  padding: 0 20px;
  font-size: 1rem;
  outline: none;
  resize: none;
  border: 1px solid white;
  &:focus {
    border: 1px solid white;
    transition: 250ms ease;
    color: black;
    &::-webkit-input-placeholder {
      transition: opacity 250ms ease;
      opacity: 0;
    }
    &::-moz-placeholder {
      transition: opacity 250ms ease;
      opacity: 0;
    }
    &:-ms-placeholder {
      transition: opacity 250ms ease;
      opacity: 0;
    }
  }
`;

const NotificationView = ({ noficationComp, setNoficationComp }) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (noficationComp.description) {
      setMessage(noficationComp.description);
    } else {
      setMessage("No description to display");
    }
  }, []);

  const handleApprove = (item) => {
    let urls = JSON.parse(item.data.replaceAll("'", '"'));
    handleNotification(urls.accept, ({ success, data }) => {
      if (success) {
        toast.success("Approved successfully!");
        handleDeleteNotification(item.id, ({ success, data }) => {
          if (success) {
            setNoficationComp("all");
          }
        });
      }
    });
  };

  const handleDeny = (item) => {
    let urls = JSON.parse(item.data.replaceAll("'", '"'));
    handleNotification(urls.decline, ({ success, data }) => {
      if (success) {
        toast.success("Denied successfully!");
        handleDeleteNotification(item.id, ({ success, data }) => {
          if (success) {
            setNoficationComp("all");
          }
        });
      }
    });
  };

  return (
    <div className="notification_container">
      <Box className="notification_box">
        <div className="cardDiv2">
          <div className="left_box">
            <div className="user_container">
              <div className="user3">
                <div
                  style={{
                    backgroundColor: "#c4c4c4",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: 100,
                    padding: 10,
                  }}
                >
                  <img src={UserCircle} alt="" />
                </div>
                <div style={{ width: "70%" }}>
                  <p className="userName">{noficationComp.actor.name}</p>
                </div>
              </div>
              <div style={{ marginTop: 55 }}>
                <div className="line3"></div>
                <Textarea
                  placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  disabled
                />
              </div>
              <div
                style={{
                  width: "95%",
                  textAlign: "right",
                  marginTop: 10,
                }}
              >
                <button
                  className="btn_deny"
                  onClick={() => handleDeny(noficationComp)}
                >
                  Deny
                </button>
                <button
                  className="btn_approve"
                  onClick={() => handleApprove(noficationComp)}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default NotificationView;
