import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import React from "react";
import Search from "../assets/search.svg";
import UserCircle from "../assets/img/UserCircle.svg";
import {
  handleGetNotification,
  handleNotification,
  handleDeleteNotification,
} from "../helper/APIFunctions";
import { toast } from "react-toastify";

const Notification = ({ setNotifyCount, setNoficationComp }) => {
  const [name, setName] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const onChangeEvent = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    handleGetNotification(({ success, data }) => {
      if (success) {
        setNotifications(data?.results);
        setFilteredNotifications(data?.results);
      }
    });
  }, []);

  const handleApprove = (item) => {
    let urls = JSON.parse(item.data.replaceAll("'", '"'));
    handleNotification(urls.accept, ({ success, data }) => {
      if (success) {
        toast.success("Approved successfully!");
        handleDeleteNotification(item.id, ({ success, data }) => {
          if (success) {
            let temp = notifications;
            temp = notifications.filter((dat) => dat.id !== item.id);
            setNotifications(temp);
            setFilteredNotifications(temp);
            setName("");
          }
        });
      }
    });
  };

  const handleDeny = (item) => {
    let urls = JSON.parse(item.data.replaceAll("'", '"'));
    handleNotification(urls.decline, ({ success, data }) => {
      if (success) {
        toast.success("Denied successfully!");
        handleDeleteNotification(item.id, ({ success, data }) => {
          if (success) {
            let temp = notifications;
            temp = notifications.filter((dat) => dat.id !== item.id);
            setNotifications(temp);
            setFilteredNotifications(temp);
            setName("");
          }
        });
      }
    });
  };
  const handleSearch = (e) => {
    setName(e.target.value);
    if (e.target.value.length > 0) {
      let temp = [];
      filteredNotifications.map((item) => {
        let name = item?.actor?.name?.toLowerCase();
        let value = e.target?.value?.toLowerCase();
        let existingValue = name?.indexOf(value);
        if (existingValue > -1) {
          temp.push(item);
        }
      });
      setFilteredNotifications(temp);
    } else {
      setFilteredNotifications(notifications);
    }
  };

  return (
    <div className="notification_container">
      <div style={{ width: "50%" }}>
        <div className="searchDiv" style={{ marginBottom: "" }}>
          <input
            type="text"
            placeholder="Search"
            className="searchField"
            name="name"
            value={name}
            autocomplete="off"
            onChange={handleSearch}
          />
          <img src={Search} className="searchIcon" alt="" />
        </div>
        <Box className="notification_box2">
          <div className="cardDiv3">
            <div className="left_box">
              <span className="notification_head2"> Notifications</span>
              <div className="line2"></div>
              {filteredNotifications.length > 0 ? (
                filteredNotifications?.map((item) => (
                  <>
                    <div className="user2">
                      <div
                        style={{
                          backgroundColor: "#c4c4c4",
                          display: "grid",
                          placeItems: "center",
                          borderRadius: 100,
                          padding: 10,
                        }}
                      >
                        <img src={UserCircle} alt="" />
                      </div>

                      <div style={{ width: "70%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "125%",
                          }}
                        >
                          <p
                            className="userName"
                            style={{ cursor: "pointer" }}
                            onClick={() => setNoficationComp(item)}
                          >
                            {item?.actor?.name}
                          </p>
                          <div className="btn_div">
                            <button
                              className="btn_deny"
                              onClick={() => handleDeny(item)}
                            >
                              Deny
                            </button>
                            <button
                              className="btn_approve"
                              onClick={() => handleApprove(item)}
                            >
                              Approve
                            </button>
                          </div>
                        </div>

                        <p className="orderIs">
                          <div
                            className="user_status"
                            style={{ cursor: "pointer" }}
                            onClick={() => setNoficationComp(item)}
                          >
                            {item?.verb?.substring(0, 80)}
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="line2"></div>
                  </>
                ))
              ) : (
                <span className="notification_head2"> No data to show</span>
              )}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Notification;
