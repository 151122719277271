import React from "react";
import Drawer from "./Drawer";

const Dashboard = () => {
	return (
		<div className="dashbordDiv">
			<Drawer />
		</div>
	);
};

export default Dashboard;
