import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import React from "react";
import styled from "@emotion/styled";
import UserCircle from "../assets/img/UserCircle.svg";
import { handleReplyMsg } from "../helper/APIFunctions";
import { toast } from "react-toastify";

const Textarea = styled.textarea`
  width: 95%;
  height: 200px;
  margin-right: 4rem;
  padding: 0 20px;
  font-size: 1rem;
  outline: none;
  resize: none;
  border: 1px solid white;
  &:focus {
    border: 1px solid white;
    transition: 250ms ease;
    color: black;
    &::-webkit-input-placeholder {
      transition: opacity 250ms ease;
      opacity: 0;
    }
    &::-moz-placeholder {
      transition: opacity 250ms ease;
      opacity: 0;
    }
    &:-ms-placeholder {
      transition: opacity 250ms ease;
      opacity: 0;
    }
  }
`;

const Notification = ({ supportUser, setSupportUser }) => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (supportUser.is_resolved) {
      setMessage(supportUser.admin_reply);
    }
  }, []);

  const handleReply = () => {
    handleReplyMsg(
      supportUser.id,
      { is_resolved: true, admin_reply: message },
      ({ success, data }) => {
        if (success) {
          toast.success("Reply sent successfully");
          setSupportUser();
        }
      }
    );
  };
  return (
    <div className="notification_container">
      <Box className="notification_box">
        <div className="cardDiv2">
          <div className="left_box">
            <div className="user_container">
              <div className="user3">
                <div
                  style={{
                    backgroundColor: "#c4c4c4",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: 100,
                    padding: 10,
                  }}
                >
                  <img src={UserCircle} alt="" />
                </div>
                <div style={{ width: "70%" }}>
                  <p className="userName">
                    {`${supportUser.user_info.name} contact support`}
                  </p>
                </div>
              </div>
              <div style={{ marginTop: 55 }}>
                <div className="line3"></div>
                <Textarea
                  placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div
                style={{
                  width: "95%",
                  textAlign: "right",
                  marginTop: 10,
                }}
              >
                <button className="send" onClick={handleReply}>
                  Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Notification;
