import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import React from "react";
import { handleGetSupportMsgs } from "../helper/APIFunctions";

import UserCircle from "../assets/img/UserCircle.svg";
const Notification = ({ setSupportUser }) => {
	const [messages, setMessages] = useState([]);
	useEffect(() => {
		handleGetSupportMsgs(({ success, data }) => {
			if (success) {
				setMessages(data.results);
			}
		});
	}, []);

	return (
		<div className="notification_container">
			<Box className="notification_box">
				<div className="cardDiv2">
					<div className="left_box">
						<span className="notification_head"> Requests</span>
						<div className="line1"></div>
						<div className="user_container">
							{messages.length > 0 ? (
								messages.map((item) => (
									<>
										<div className="user1">
											<div
												style={{
													backgroundColor: "#c4c4c4",
													display: "grid",
													placeItems: "center",
													borderRadius: 100,
													padding: 10,
												}}
											>
												<img src={UserCircle} alt="" />
											</div>

											<div style={{ width: "70%" }}>
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
														width: "100%",
													}}
												>
													<p className="userName">
														{`${item.user_info.name} support`}
													</p>
													<div className="btn_div">
														<button
															className="btn_approve2"
															onClick={() => {
																setSupportUser(
																	item
																);
															}}
														>
															{item.is_resolved
																? "Solved"
																: "Open"}
														</button>
													</div>
												</div>
												<p className="orderIs">
													<div className="user_status">
														{item.message.substring(
															0,
															80
														)}
													</div>
												</p>
											</div>
										</div>
										<div className="line1"></div>
									</>
								))
							) : (
								<span className="notification_head">
									{" "}
									No data to show
								</span>
							)}
						</div>
					</div>
				</div>
			</Box>
		</div>
	);
};

export default Notification;
