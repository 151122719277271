import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import React from "react";
import { Divider } from "@mui/material";
import { handleMassNotification } from "../helper/APIFunctions";
import UserCircle from "../assets/img/UserCircle.svg";
import moment from "moment";

const Notification = ({ setComp }) => {
	const [messages, setMessages] = useState([]);
	useEffect(() => {
		handleMassNotification(({ success, data }) => {
			if (success) {
				setMessages(data.results);
			}
		});
	}, []);
	return (
		<div className="notification_container">
			<Box className="notification_box">
				<div className="cardDiv2">
					<div className="left_box">
						<span className="notification_head">
							{" "}
							Messages Sent
						</span>
						<div className="line1"></div>
						<div className="user_container">
							{messages.length > 0 ? (
								messages.map((item) => (
									<>
										<div className="user1">
											<div
												style={{
													backgroundColor: "#c4c4c4",
													display: "grid",
													placeItems: "center",
													borderRadius: 100,
													padding: 10,
												}}
											>
												<img src={UserCircle} alt="" />
											</div>

											<div style={{ width: "70%" }}>
												<p className="userName">
													{item.users === "A"
														? `All contact ${moment(
																item.created_at
														  ).format(
																"DD-MM-YYYY"
														  )}`
														: item.users === "P"
														? `Parents contact ${moment(
																item.created_at
														  ).format(
																"DD-MM-YYYY"
														  )}`
														: `Organizers contact ${moment(
																item.created_at
														  ).format(
																"DD-MM-YYYY"
														  )}`}
												</p>
												<p className="orderIs">
													<div className="user_status">
														{item.verb.substring(
															0,
															80
														)}
													</div>
												</p>
											</div>
										</div>
										<div className="line1"></div>
									</>
								))
							) : (
								<span className="notification_head">
									{" "}
									No data to show
								</span>
							)}
						</div>
					</div>
				</div>
			</Box>
			<button
				style={{
					backgroundColor: "#F09491",
					color: "white",
					padding: "10px 20px",
					border: 0,
					cursor: "pointer",
				}}
				onClick={() => setComp("new")}
			>
				NEW MESSAGE
			</button>
		</div>
	);
};

export default Notification;
