import axios from "axios";
import { globalOptions } from "./constants";

class API {
	static request({ method, url, token, data = {} }) {
		console.log(globalOptions.baseURL + url);
		return axios({
			method,
			url: globalOptions.baseURL + url,
			...(Object.keys(data).length !== 0 && {
				data: JSON.stringify(data),
			}),
			headers: {
				accept: "application/json",
				"Content-Type": "application/json",
				...(token && { Authorization: `JWT ${token}` }),
			},
		})
			.then((res) => res.data)
			.catch((err) => {
				throw err;
			});
	}
	static formRequest({ method, url, token, data = {} }) {
		console.log({ Authorization: `JWT ${token}` });
		return axios({
			method,
			url: globalOptions.baseURL + url,
			data,
			headers: {
				accept: "application/json",
				"Content-Type": "multipart/form-data",

				...(token && { Authorization: `JWT ${token}` }),
			},
		})
			.then((res) => res.data)
			.catch((err) => {
				throw err;
			});
	}
}

export default API;
