import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { UpdateStore } from "../Context/StoreContext";
import {
  handleGetNotification,
  handleGetSupportMsgs,
} from "../helper/APIFunctions";
// Drawer Icons
import { ReactComponent as Icon2 } from "../assets/DrawerIcons/Users.svg";
import { ReactComponent as ChatIcon } from "../assets/DrawerIcons/chatIcon.svg";
import { ReactComponent as CardIcon } from "../assets/DrawerIcons/CreditCard.svg";
import { ReactComponent as Notifications } from "../assets/DrawerIcons/Bell.svg";
// components
import MassNotification from "../Components/MassNotification";
import NewMessage from "../Components/NewMessage";
import Notification from "../Components/Notification";
import Support from "../Components/Support";
import SupportMessage from "../Components/SupportMessage";
import NotificationView from "../Components/NotificationView";
import * as FileSaver from "file-saver";
import Badge from "@mui/material/Badge";
import axios from "axios";
import moment from "moment";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer(props) {
  const updater = UpdateStore();
  const [active, setActive] = React.useState("MASS NOTIFICATION LIST");
  const [massComp, setMassComp] = React.useState("all");
  const [noficationComp, setNoficationComp] = React.useState("all");
  const [supportUser, setSupportUser] = React.useState();
  const [notifyBCount, setNotifyCount] = React.useState();
  const [supportBCount, setSupportCount] = React.useState();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    handleGetNotification(({ success, data }) => {
      if (success) {
        setNotifyCount(data.count);
      }
    });
    handleGetSupportMsgs(({ success, data }) => {
      if (success) {
        setSupportCount(data.count);
      }
    });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userData");
    updater({ loggedIn: false, user: {}, key: "" });
  };

  let IconIs = [
    {
      text: "MASS NOTIFICATION LIST",
      icon: Icon2,
    },

    {
      text: "NOTIFICATIONS",
      icon: Notifications,
    },
    {
      text: "SUPPORT",
      icon: ChatIcon,
    },
    {
      text: "Export",
      icon: CardIcon,
    },
  ];
  const drawer = (
    <>
      {IconIs.map((item, index) => (
        <div style={{ marginTop: index === 0 && 80 }}>
          <Badge
            badgeContent={
              index === IconIs.length - 1 || index === 0
                ? 0
                : index == 1
                ? notifyBCount
                : supportBCount
            }
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: "#EFBE7D",
                marginRight: 2,
                marginTop: 2,
              },
            }}
          >
            <item.icon
              fill={`${
                item.text === active && item.text !== "Export"
                  ? "#F09491"
                  : "white"
              }`}
              onClick={() => {
                if (item.text !== "Export") {
                  setActive(item.text);
                  setMassComp("all");
                  setNoficationComp("all");
                  setSupportUser();
                } else {
                  let token = localStorage.getItem("userToken");
                  axios
                    .get(
                      `https://dev.kidgystics.com/api/v1/dashboard/download-report/`,
                      {
                        headers: {
                          Authorization: `JWT ${token}`,
                        },
                        responseType: "blob",
                      }
                    )
                    .then((resp, err) => {
                      FileSaver.saveAs(
                        new Blob([resp.data]),
                        `Activity_Report_${moment(new Date()).format(
                          "YYYY_MM_DD"
                        )}.xlsx`
                      );
                    });
                }
              }}
              className={`${
                item.text === active && item.text !== "Export"
                  ? "iconimgactive"
                  : "iconimg"
              }`}
            />
          </Badge>
        </div>
      ))}
    </>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <Toolbar>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 10,
              marginRight: -30,
            }}
          >
            <Typography noWrap component="div" className="drawer_active_title">
              {active}
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: 10,
              marginRight: 30,
              cursor: "pointer",
            }}
            onClick={handleLogout}
          >
            <Typography noWrap className="drawer_active_title2">
              LOG OUT
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        style={{ backgroundColor: " #31C7CC !important" }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {drawer}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {active === "MASS NOTIFICATION LIST" &&
          (massComp === "all" ? (
            <MassNotification setComp={setMassComp} />
          ) : (
            <NewMessage setComp={setMassComp} />
          ))}
        {active === "NOTIFICATIONS" &&
          (noficationComp === "all" ? (
            <Notification
              setNotifyCount={setNotifyCount}
              setNoficationComp={setNoficationComp}
            />
          ) : (
            <NotificationView
              noficationComp={noficationComp}
              setNoficationComp={setNoficationComp}
            />
          ))}
        {active === "SUPPORT" &&
          (!supportUser ? (
            <Support setSupportUser={setSupportUser} />
          ) : (
            <SupportMessage
              supportUser={supportUser}
              setSupportUser={setSupportUser}
            />
          ))}
      </Box>
    </Box>
  );
}
