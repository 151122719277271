import { useState } from "react";
import { Box } from "@mui/system";
import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";
import UserCircle from "../assets/img/UserCircle.svg";
import { handleSendMessage } from "../helper/APIFunctions";
import { toast } from "react-toastify";

const Textarea = styled.textarea`
	width: 95%;
	height: 270px;
	margin-right: 4rem;
	padding: 0 20px;
	font-size: 1rem;
	outline: none;
	resize: none;
	border: 1px solid white;
	&:focus {
		border: 1px solid white;
		transition: 250ms ease;
		color: black;
		&::-webkit-input-placeholder {
			transition: opacity 250ms ease;
			opacity: 0;
		}
		&::-moz-placeholder {
			transition: opacity 250ms ease;
			opacity: 0;
		}
		&:-ms-placeholder {
			transition: opacity 250ms ease;
			opacity: 0;
		}
	}
`;

const Notification = ({ setComp }) => {
	const [message, setMessage] = useState("");
	const [sendTo, setSendTo] = useState("A");

	const handleSend = () => {
		handleSendMessage(
			{ verb: message, users: sendTo },
			({ success, data }) => {
				if (success) {
					toast.success("Message sent successfully");
					setComp("all");
				}
			}
		);
	};
	return (
		<div className="notification_container">
			<Box className="notification_box">
				<div className="cardDiv2">
					<div className="left_box">
						<div className="user_container">
							<div className="user3">
								<div
									style={{
										backgroundColor: "#c4c4c4",
										display: "grid",
										placeItems: "center",
										borderRadius: 100,
										padding: 10,
									}}
								>
									<img src={UserCircle} alt="" />
								</div>

								<div style={{ width: "70%" }}>
									<p className="userName">Gyst Team</p>
								</div>
							</div>
							<div style={{ marginTop: 55 }}>
								<div className="line3"></div>
								<Textarea
									placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
									type="text"
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</Box>
			<div className="send_container">
				<p>
					<strong>SEND MESSAGE TO:</strong>
					<br />
					F...
				</p>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								sx={{
									color: "#F09491",
									"&.Mui-checked": {
										color: "#F09491",
									},
								}}
								checked={sendTo === "A"}
								onChange={() => setSendTo("A")}
							/>
						}
						label={<strong>Select All</strong>}
						style={{ marginBottom: 15 }}
					/>
					<FormControlLabel
						control={
							<Checkbox
								sx={{
									color: "#F09491",
									"&.Mui-checked": {
										color: "#F09491",
									},
								}}
								checked={sendTo === "P"}
								onChange={() => setSendTo("P")}
							/>
						}
						label={<strong>Parents</strong>}
						style={{ marginBottom: 15 }}
					/>
					<FormControlLabel
						control={
							<Checkbox
								sx={{
									color: "#F09491",
									"&.Mui-checked": {
										color: "#F09491",
									},
								}}
								checked={sendTo === "O"}
								onChange={() => setSendTo("O")}
							/>
						}
						label={<strong>Organizations</strong>}
						style={{ marginBottom: 15 }}
					/>
				</FormGroup>
				<button className="send" onClick={handleSend}>
					Reply
				</button>
			</div>
		</div>
	);
};

export default Notification;
