import "./App.css";
import Index from "./routes/index";
import "./css/index.css";
import { StoreProvider } from "./Context/StoreContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
	return (
		<>
			<StoreProvider>
				{/* <Login/> */}
				{/* <Splash/> */}
				{/* <Dashboard /> */}
				<Index />
				<ToastContainer />
			</StoreProvider>
		</>
	);
}

export default App;
