const APIs = {
	Login: { method: "POST", url: "/rest-jwt/api-token-auth/" },
	Export: { method: "GET", url: "/api/v1/dashboard/download-report/" },
	Notification: {
		method: "GET",
		url: "/api/v1/notification/custom_notifications/",
	},
	SupportMsgs: {
		method: "GET",
		url: "/api/v1/supports/",
	},
	MassNotification: {
		method: "GET",
		url: "/api/v1/dashboard/mass-notify/",
	},
	SendMessage: {
		method: "POST",
		url: "/api/v1/dashboard/mass-notify/",
	},
};

export default APIs;
