import { toast } from "react-toastify";
import API from "../helper/Api";
import APIs from "../helper/API_List";

export const handleSignIn = (data, callback = () => {}) => {
  const { method, url } = APIs.Login;
  console.log(data);
  return API.request({ data, method, url })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v[0]));
      callback({ success: false, data: err.response.data });
    });
};

export const handleExport = (callback = () => {}) => {
  const { method, url } = APIs.Export;
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token })
    .then((res) => {
      callback({ success: true, response: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleGetNotification = (callback = () => {}) => {
  const { method, url } = APIs.Notification;
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleGetSupportMsgs = (callback = () => {}) => {
  const { method, url } = APIs.SupportMsgs;
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleMassNotification = (callback = () => {}) => {
  const { method, url } = APIs.MassNotification;
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleSendMessage = (data, callback = () => {}) => {
  const { method, url } = APIs.SendMessage;
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token, data })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleReplyMsg = (id, data, callback = () => {}) => {
  const method = "PUT";
  const url = `/api/v1/supports/${id}/`;
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token, data })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleNotification = (url, callback = () => {}) => {
  const method = "POST";
  let token = localStorage.getItem("userToken");
  return API.request({ method, url, token })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};

export const handleDeleteNotification = (id, callback = () => {}) => {
  const method = "DELETE";
  let token = localStorage.getItem("userToken");
  const url = `/api/v1/notification/custom_notifications/${id}/`;
  return API.request({ method, url, token })
    .then((res) => {
      callback({ success: true, data: res });
    })
    .catch((err) => {
      console.log(err);
      Object.values(err.response.data).map((v) => toast.error(v));
      callback({ success: false, data: err.response.data });
    });
};
