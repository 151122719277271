import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Dashboard from "../container/Dashboard";
import Login from "../container/Login";
import { Store, UpdateStore } from "../Context/StoreContext";

const Index = () => {
  const { loggedIn, ...rest } = Store();
  const updater = UpdateStore();
  useEffect(() => {
    let token = localStorage.getItem("userToken");
    let user = "";
    try {
      user = JSON.parse(localStorage.getItem("userData"));
    } catch (e) {}
    updater({ loggedIn: Boolean(token), user, key: token });
  }, []);

  return (
    <Router>
      <Routes>
        {loggedIn ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default Index;
