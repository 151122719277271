import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import Loginicon1 from "../assets/img/loginicon.png";
import { UpdateStore } from "../Context/StoreContext";
import { handleSignIn } from "../helper/APIFunctions";

const Login = () => {
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const updater = UpdateStore();

  return (
    <div className="a">
      <div className="formDiv">
        <img src={Loginicon1} alt="" />
        <div className="fields-container">
          <div>
            <p className="textfiledp">Username</p>
            <input
              value={state.username}
              type="text"
              className="textField"
              onChange={(e) =>
                setState((s) => ({
                  ...s,
                  username: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <p className="textfiledp">Password</p>
            <input
              value={state.password}
              type="password"
              className="textField"
              onChange={(e) =>
                setState((s) => ({
                  ...s,
                  password: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <Button
          className="signinButton"
          onClick={() => {
            setLoading(true);
            handleSignIn(state, ({ success, data }) => {
              if (success) {
                console.log(data, "data");
                localStorage.setItem("userToken", data.token);
                localStorage.setItem("userData", JSON.stringify(data.user));

                updater({
                  loggedIn: true,
                  ...data,
                });
              }
            }).finally((res) => setLoading(false));
          }}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : "Sign in"}
        </Button>
      </div>
    </div>
  );
};

export default Login;
